import React from'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typo from '@mui/material/Typography';

import{makePage}from'../makePage'


const PageWhatsNext=(props)=>makePage({propsGrid:{
  container:{
    children:<Card>
      <CardContent>
        <Typo variant='h4' color='primary'>What's Next?</Typo>
        <Typo variant='h6' color='primary'>Look for Book Two in the Penelope Penny series coming out Fall 2022!</Typo>
      </CardContent>
    </Card>,
  },
},props})


export default PageWhatsNext
